.testBed {
    display: grid;
}
.rootContainer {
    height: 388px;
    width: 380px;
    position: absolute;
    display: flex;
    flex-direction: column;
}
.rootContainer > div {
    margin: 10px;
}
.CondensedParent {
    display: flex;
    position: relative;
    background-color: blue;
    align-content: stretch;
    align-items: stretch;
    justify-content: space-around;
}
.DepthProfileTest {
    width: 300px;
    height: 175px;
    background-color: pink;
}
.CurrentValuesTest {
    flex-grow: 1;
    align-self: stretch;
}
.details {
    flex-grow: 1;
    width: 50px;
}

.RankStepperIconTest {
    text-align: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid var(--palette-main) 2px;
    padding: 0.1rem 0;
    margin-top: auto;
    margin-bottom: auto;
    background: var(--palette-main);
    color: white;
}

.RankStepperIconInputTest {
    color: white;
    font-size: 1.2rem;
}

.RankStepperIconInputTest .MuiInputBase-input {
    text-align: center;
}
